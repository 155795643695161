
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import AddMemberDialog from "@/components/team/AddMemberDialog"
import { SharePermission } from "@evercam/shared/types/shares"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default {
  name: "ProjectMembers",
  meta: {
    pageId: AnalyticsEventPageId.ProjectMembers,
  },
  components: {
    AddMemberDialog,
  },
  data() {
    return {
      dialog: false,
      members: [],
      isLoading: false,
      height: "100%",
      selectedShareToUpdate: {},
      showFilters: false,
      memberSearch: "",
      userSelectedCameras: [],
    }
  },
  computed: {
    ...mapStores(useProjectStore, useCameraStore, useAccountStore),
    computedMembers() {
      return this.members.filter(
        (member) =>
          member.name.toLowerCase().includes(this.memberSearch.toLowerCase()) ||
          member.email.toLowerCase().includes(this.memberSearch.toLowerCase())
      )
    },
    projectCameras() {
      return this.projectStore.selectedProjectCameras
    },
    selectedCamerasText() {
      if (this.userSelectedCameras.length === this.projectCameras.length) {
        return "All Cameras"
      }

      return `${this.userSelectedCameras.length} Cameras`
    },
    headers() {
      let setWidth
      if (this.projectCameras.length < 4) {
        setWidth = "200px"
      } else if (this.projectCameras.length < 6) {
        setWidth = "155px"
      } else {
        setWidth = "120px"
      }

      return [
        {
          text: "Member",
          value: "name",
          width: "200px",
          sortable: true,
        },
        ...this.projectCameras.map((camera) => ({
          text: camera.name,
          value: camera.id,
          sortable: false,
          width: setWidth,
          isCamera: true,
        })),
        {
          align: "left",
          sortable: false,
          text: "",
          value: "extraColumn",
        },
      ]
    },
    filteredHeaders() {
      const filteredHeaders = this.headers.filter(
        (header) =>
          this.userSelectedCameras.includes(header.value) || !header.isCamera
      )

      if (this.projectCameras.length > 5) {
        // Exclude the extraColumn header when camera length is greater than 5
        return filteredHeaders.filter(
          (header) => header.value !== "extraColumn"
        )
      } else {
        return filteredHeaders
      }
    },
    rights() {
      return [
        {
          text: this.$t("content.shares.create_form.rights_labels.none"),
          value: null,
          color: "grey",
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.read_only"),
          value: SharePermission.Minimum,
          color: "primary",
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ),
          value: SharePermission.Share,
          color: "primary",
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.full"),
          value: SharePermission.Full,
          disabled:
            this.cameraStore.selectedCamera?.rights.indexOf("edit") === -1,
          color: "primary",
        },
      ]
    },
  },
  watch: {
    dialog(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.ProjectMembersToggleAddDialog, {
        visible,
      })
    },
  },
  async created() {
    await this.fetchShares()
    this.userSelectedCameras = this.projectCameras.map((camera) => camera.id)
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    handleMembersContainerDimensionsChange() {
      return
    },
    async fetchShares(delayRequest = false) {
      try {
        this.isLoading = true
        const cameraExids = this.projectCameras.map((camera) => camera.id)
        const getCameraPermission = function (rights) {
          if (rights.indexOf("edit") !== -1) {
            return SharePermission.Full
          } else if (rights.indexOf("share") !== -1) {
            return SharePermission.Share
          }

          return SharePermission.Minimum
        }

        // Shameless hack : Give the backend some time to update the shares, othewise we'll get outdated data. This is a temporary solution.
        // TODO : Remove this hack once the project sharing endpoint is implemented
        if (delayRequest) {
          await new Promise((resolve) => setTimeout(resolve, 2000))
        }

        const responses = await Promise.all(
          cameraExids.map((exid) => EvercamApi.shares.cIndex(exid))
        )

        // Combine all shares from all responses into a single array
        const allShares = responses.flatMap((response) => response.shares)

        // Group shares by email
        const sharesByEmail = allShares.reduce((groups, share) => {
          ;(groups[share.email] = groups[share.email] || []).push(share)

          return groups
        }, {})

        // Map each group of shares to a member object
        this.members = Object.values(sharesByEmail).map((shares) => {
          const firstShare = shares[0]
          let member = {
            name: firstShare.fullname,
            email: firstShare.email,
          }
          const cameras = cameraExids.map((cameraExid) => {
            const owner = responses.find(
              (response) => response.shares[0]?.cameraId === cameraExid
            )?.owner

            return {
              exid: cameraExid,
              ownerEmail: owner?.email,
            }
          })
          cameras.forEach((camera) => {
            const exid = camera.exid
            const ownerEmail = camera.ownerEmail
            const cameraShare =
              shares.find((share) => share.cameraId === exid) || null
            if (!cameraShare) {
              member[exid] = {
                permission: null,
                isOwner: false,
              }

              return
            }
            member[exid] = {
              permission: getCameraPermission(cameraShare.rights) || null,
              isOwner: ownerEmail === firstShare.email,
            }
          })

          return member
        })

        if (!this.accountStore.email.includes("@evercam.io")) {
          this.members = this.members.filter(({ email }) => {
            return email !== "demo@evercam.io"
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    handleResizeTable() {
      const top = this.$refs.table.$el.getBoundingClientRect().top
      this.height = `${window.innerHeight - top - 40}px`
    },
    generateRightList(permissions) {
      let rights = ["list", "view", "snapshot"]

      if (permissions === SharePermission.Share) {
        rights = [...rights, "share"]
      } else if (permissions === SharePermission.Full) {
        rights = [...rights, "share", "edit"]
      }

      return rights.join(",")
    },
    async handleCameraShare(member, cameraId, event) {
      const oldPermission = member[cameraId].permission
      const newPermission = event?.value
      const shareData = this.getShareData(member, newPermission)

      try {
        const permissions = {
          [SharePermission.Minimum]: "Read Only",
          [SharePermission.Share]: "Read + Share",
          [SharePermission.Full]: "Full Rights",
        }
        const permissionLabel = permissions[newPermission]
        if (oldPermission && newPermission) {
          this.$analytics.saveEvent(
            AnalyticsEvent.ProjectMembersEditCameraAccess,
            { permission: permissionLabel }
          )
          await this.updateShare(cameraId, shareData)
        } else if (!oldPermission && newPermission) {
          this.$analytics.saveEvent(AnalyticsEvent.ProjectMembersShareCamera, {
            permission: permissionLabel,
          })
          await this.createShare(cameraId, shareData)
        } else if (oldPermission && !newPermission) {
          this.$analytics.saveEvent(
            AnalyticsEvent.ProjectMembersDeleteCameraAccess
          )
          await this.deleteShare(cameraId, member.email)
        }

        this.updateMember(member.email, cameraId, newPermission)
      } catch (error) {
        this.$notifications.error({
          text: "Camera share failed :",
          error,
        })

        this.updateMember(member.email, cameraId, oldPermission)
      }
    },

    async createShare(cameraId, shareData) {
      await EvercamApi.shares.cCreate(cameraId, shareData)
      this.$notifications.success("Camera successfully shared with the user.")
    },
    async updateShare(cameraId, shareData) {
      await this.$axios.patch(`/cameras/${cameraId}/shares`, shareData)
      this.$notifications.success("Rights successfully updated.")
    },
    async deleteShare(cameraId, email) {
      await EvercamApi.shares.deleteShare(cameraId, { params: { email } })
      this.$notifications.success("Camera successfully unshared with the user.")
    },
    getShareData(member, newPermission) {
      return {
        email: member.email,
        rights: this.generateRightList(newPermission),
      }
    },
    updateMember(email, cameraId, newPermission) {
      this.members = this.members.map((memberItem) => {
        if (memberItem.email === email) {
          memberItem[cameraId] = {
            permission: newPermission,
            isOwner: false,
          }
        }

        return memberItem
      })
    },
  },
}
